import isEqual from "lodash/isEqual"

export default {
  filterByKey(object, keyName) {
    return Object.keys(object)
      .filter((key) => key.includes(keyName))
      .reduce((cur, key) => {
        return Object.assign(cur, {
          [key]: object[key],
        })
      }, {})
  },
  areEqual(obj1, obj2) {
    return isEqual(obj1, obj2)
  },
}
